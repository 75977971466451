@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
.underline {
    &::before {
        position: absolute;
        content: '';
        background-color: $secondary;
        width: 32px;
        height: 6px;
        bottom: 0px;
        border-radius: 5px;
    }

    &--accent::before {
        background-color: $accent;
    }
}


.shadow-light {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.02), 0 10px 14px 1px rgba(0, 0, 0, 0.02), 0 4px 18px 3px rgba(0, 0, 0, 0.02);
}

.sleeve {
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;

    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -50px;
        height: 50px;
        width: 25px;
        left: 0;
        border-top-left-radius: 25px;
        box-shadow: 0 -25px 0 0 white;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -50px;
        height: 50px;
        width: 25px;
        right: 0;
        border-top-right-radius: 25px;
        box-shadow: 0 -25px 0 0 white;
        z-index: -1;
    }
}

.bg-none {
    background-color: transparent;
}

.html, body, #q-app {
    font-family: "Poppins", "Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px;
}

.q-table--dense .q-table th:last-child, .q-table--dense .q-table td:last-child {
    padding-right: 0px;
}

.q-table--dense .q-table th:first-child, .q-table--dense .q-table td:first-child {
    padding: 0px;
    margin: 0px;
}

.q-table--dense .q-table th, .q-table--dense .q-table td {
    padding: 0px 0px;
}

.q-table--dense .q-table thead tr, .q-table--dense .q-table tbody tr, .q-table--dense .q-table tbody td {
    height: 19px;
    width: 25px
}


.of-page-column {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.of-page-column-small {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}
