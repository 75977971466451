
































:root {
    --overlay-bg: #1867c0;
    --transition-duration: 0.35s;
}
